

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  color: #fff;
  padding: 0px 2rem;
  min-height: 48px;
}

.logo h1 {
  margin: 0;
  font-size: 24px;
}

nav {
  display: flex;
  align-items: center;
  height: 100%;
}

.nav-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;
}

.nav-links li {
  display: flex;
  align-items: center;
  margin: auto 2.0rem;
  height: 100%;
  white-space: nowrap;
}

.nav-links li a {
  display: flex;
  align-items: center;
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  height: 100%;
}

.nav-links li a:hover {
  text-decoration: underline;
}

.nav-links li a.active {
  color: #02EEFB;

}

.menu-toggle {
  display: none;
  font-size: 24px;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .nav-links {
    flex-direction: column;
    display: none;
  }
  
  .nav-links.active {
    display: flex;
  }

  .nav-links li {
    margin: 10px 0;
  }

  .menu-toggle {
    display: block;
  }
}
