.contact-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem auto 4rem auto;
    padding: 0px 20px 40px 20px;
    min-width: fit-content;
    width: 38rem;
    max-width: 88vw;
    height: fit-content;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.contact-form-container h2 {
    margin-bottom: 16px;
    color: #333;
}

.contact-form {
    display: flex;
    flex-direction: column;
    width: 100%;
}
 
.contact-form input,
.contact-form textarea {
    margin-bottom: 15px;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
}

.contact-form textarea {
    min-height: 160px;
    resize: vertical;
}


.send-button {
    padding: 12px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.send-button:hover {
    background-color: #0056b3;
}
/**/





.contact-form-container {
    position: relative;
}
  


.contact-form .send-button:disabled {
    background-color: #bbb;
    cursor: not-allowed;
}

.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.8);
    font-size: 1.5em;
    color: #333;
    animation: fadeIn 0.5s;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}