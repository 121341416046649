.ressources-page {
    padding: 20px;
  }
  
  .ressources-page h1 {
    font-size: 28px;
  }
  
  .ressources-page ul {
    list-style: none;
    padding: 0;
  }
  
  .ressources-page ul li {
    margin: 10px 0;
  }
  
  .ressources-page ul li a {
    text-decoration: none;
    color: #333;
    font-size: 18px;
  }
  
  .ressources-page ul li a:hover {
    text-decoration: underline;
  }
  