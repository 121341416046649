

/* Hackathon2023.css */
.page-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.page-header {
    display: flex;
    justify-content: space-around;
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.header-element {
    display: flex;
    align-items: center;
}

.link button {
    padding: 5px 10px;
    cursor: pointer;
}

/* Define the animation */
@keyframes colorChange {
    0% {
        background-color: #ff6347; /* Tomato */
        color: #000;
    }
    25% {
        background-color: #4682b4; /* SteelBlue */
        color: #fff;
    }
    50% {
        background-color: #32cd32; /* LimeGreen */
        color: #000;
    }
    75% {
        background-color: #ff1493; /* DeepPink */
        color: #fff;
    }
    100% {
        background-color: #ff6347; /* Tomato (repeat) */
        color: #000;
    }
}

.link button {
    padding: .6rem 1rem;
    cursor: pointer;
    border: none;
    color: #fff;
    background-color: #ff6347; /* Tomato */
    font-size: 16px;
    border-radius: 5px;
    animation: colorChange 8s infinite; /* Apply the animation */
    transition: background-color 0.3s ease; /* Smooth transition for color change */
}
.link button:hover {
    background-color: #ff4500; /* OrangeRed */
}



.page-body {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: flex-start;
}

.documents-list {
    display: flex;
    flex-direction: column;
    margin: 10px;
}

.document-item {
    cursor: pointer;
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.document-item:hover {
    background-color: #e0e0e0;
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.pdf-container {
    position: relative;
    min-width: 660px;
    width: 80%;
    max-width: 100%;
    height: 85vh;
    padding: 2px;
    border: 2px solid black;
}
