.root-application {
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 1fr;
    /* width: 100vw; */
    max-width: 100vw;
    min-height: 100vh;
    /* max-height: 100vh; */
    /* background-color: #20242D; */
    background-color: #d8e1f6;
}