.page-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    max-width: 100vw;
    height: fit-content;
    max-height: auto;
}

.page-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.header-element {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    justify-content: center;
    align-items: center;
    margin: auto 1.2rem;
}

.page-body {
    margin: 0 0 1rem 0;
}

.h1 {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 2rem;
    font-weight: 700;
    margin-block: .25rem;
}

