footer {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 20px;
    position: relative;
    bottom: 0;
    /* width: 100vw; */
    max-width: 100vw;
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
}

.footer-content p {
    margin: 0;
    font-size: 14px;
}

.social-links {
    margin-top: 10px;
}

.social-links a {
    color: #fff;
    text-decoration: none;
    margin: 0 10px;
    font-size: 16px;
}

.social-links a:hover {
    text-decoration: underline;
}
